//import { createCheckoutSession } from "@stripe/firestore-stripe-payments";
import { loadStripe } from '@stripe/stripe-js';
import { payments, db, collection, addDoc, setDoc, doc, onSnapshot, getDoc, updateDoc, increment, runTransaction } from './firebase';
import useAuth from './useAuth';
import UserContext from './UserContext';
const stripePromise = loadStripe('pk_live_51NpIWlLgUN2PxGf93f7HiaRjnnBXkBKQJ2xui70Hxz2LV0QYU5UaVKnc6mzFtDWTo9Y0NvupYWO1NVuJN9p2ogQZ00d0lmMGRZ');


export const createCheckoutSession = async (user, purchaseMinutes) => {
  if (!user || !user.uid || !purchaseMinutes || purchaseMinutes <= 0) {
    console.error('Invalid user or purchase minutes');
    return;
  }

  try {
    const customerRef = doc(db, 'customers', user.uid);
    let customerDoc = await getDoc(customerRef);
    if (!customerDoc.exists()) {
      await setDoc(customerRef, {});
      customerDoc = await getDoc(customerRef);
    }
    const customerData = customerDoc.data();

    // Create a reference to the Stripe checkout sessions collection
    const checkoutSessionsRef = collection(db, 'customers', user.uid, 'checkout_sessions');

    // Create a new checkout session document
    const docRef = await addDoc(checkoutSessionsRef, {
      customer: customerData.stripeId, // Pass the Stripe customer ID
      line_items: [
        {
          price: 'price_1NxUz9LgUN2PxGf9EqMU9mqZ', // Replace with your price ID
          quantity: purchaseMinutes,
        },
      ],
      mode: 'payment',
      success_url: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}&user_id=${user.uid}`,
      cancel_url: `${window.location.origin}/cancel`,
      userId: user.uid,
      allow_promotion_codes: true,
    });

    const sessionId = docRef.id;
    const stripe = await stripePromise;
    //updateMinutes(user.uid);

    if (process.env.NODE_ENV === 'development') {
      // Manually trigger the "checkout.session.completed" event
      const checkoutSessionRef = doc(db, 'customers', user.uid, 'checkout_sessions', sessionId);
      await updateDoc(checkoutSessionRef, { payment_status: 'paid' });
    }

    // Listen for changes in the checkout session document
    onSnapshot(docRef, async (snap) => {
      try {
        if (snap.exists()) {
          const { sessionId, payment_status, userId } = snap.data();
          if (sessionId) {
            console.log('Session ID:', sessionId);
              if (payment_status == 'paid') {
              // Call the updateMinutes function when the payment is successful
              //await updateMinutes(userId);
            }
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
              console.error(error);
            } else {
            }
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    });
  } catch (error) {
    console.error('An error occurred:', error);
  }
};
