import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import './App.css';
import useAuth from './useAuth'; // Import the custom hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { db, doc, onSnapshot,collection, getDoc, updateDoc}from './firebase'; // Import Firestore
import { createCheckoutSession, purchaseMinutes } from './Checkout';
import { createTranscription} from './firestoreSetup';
import UserContext from './UserContext'; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FilterDramaOutlinedIcon from '@mui/icons-material/FilterDramaOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import Box from '@mui/material/Box';
import loadingGif from './giphy (6).gif'; 
import { onFileChange } from './CloudStorage';
//import AudioFileIcon from './AudioVector.svg';
import CustomerBanner from './CustomerBanner';
import { Helmet } from 'react-helmet';


function UploadPage() {
  const { user, setUser, loading, signOut, token, updateUserMinutes} = useContext(UserContext);
  const navigate = useNavigate(); // Get the navigate function
  const [file, setFile] = useState(null);
  const [transcription, setTranscription] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileDuration, setFileDuration] = useState(0);
  const [transcripts, setTranscripts] = useState([]);
  const [purchaseMinutes, setPurchaseMinutes] = useState(0);
  const [transcribing, setTranscribing] = useState(false);
  const fileInputRef = useRef(null);
  const [needsMoreMinutes, setNeedsMoreMinutes] = useState(false);
  
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setFileName(uploadedFile.name);
    setFileUploaded(true);

    // Create an object URL for the file
    const objectUrl = URL.createObjectURL(uploadedFile);
    let audio = new Audio(objectUrl);

    // Once the metadata has been loaded, get the duration and revoke the object URL
    audio.onloadedmetadata = function() {
      setFileDuration(audio.duration / 60); // Convert duration from seconds to minutes
      URL.revokeObjectURL(objectUrl);
    };
  };
/*
  useEffect(() => {
    // Add the class to the body element when the component is mounted
    document.body.classList.add('upload-page-background');

    // Remove the class from the body element when the component is unmounted
    return () => {
      document.body.classList.remove('upload-page-background');
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
  // ...*/

  const handlePurchaseMinutesChange = (event) => {
    setPurchaseMinutes(event.target.value);
  };

  const updateTranscription = async (uid, transcriptionId, transcription) => {
    const docRef = doc(db, "transcriptions", transcriptionId);
    await updateDoc(docRef, {
      transcription: transcription,
      updatedAt: new Date().toISOString()
    });
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    try {
      console.log('handleFileUpload called'); // Log when the function is called
      if (!user || !user.token) {
        console.log('User not authenticated'); // Log when the user is not authenticated
        navigate('/auth');
        return;
      }
      if (!file) {
        console.log('No file selected'); // Log when no file is selected
        setError('Error - You have to upload an audio file first.');
        return;
      }
      if (user.minutes === 0) {
        console.log('User has no minutes left'); // Log when the user has no minutes left
        setError('Error - You have no minutes left. Please purchase more minutes.');
        setNeedsMoreMinutes(true); // Set needsMoreMinutes to true
        return;
      }
      if (user.minutes < fileDuration) {
        const additionalMinutesNeeded = fileDuration - user.minutes;
        setError(`Warning - You only have ${user.minutes.toFixed(2)} minutes left.\nPlease purchase an additional ${additionalMinutesNeeded.toFixed(2)} minutes to transcribe the entire audio file.`);
        setNeedsMoreMinutes(true); // Set needsMoreMinutes to true
        return;
      }
      console.log('Creating transcription...'); // Log before creating the transcription
      setTranscribing(true); 
      if (file){
        // Get the signed URL for the uploaded file
        const signedUrl = await onFileChange({ target: { files: [file] } }, user.uid);
        const formData = new FormData();
        formData.append('url', signedUrl);  // Add the signed URL to the form data
        formData.append('filename', file.name);  // Add the filename to the form data

        const config = {
          headers: {
            'Authorization': `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data'
          },
        };
        console.log('Sending POST request...'); // Log before sending the POST request
        console.log([...formData]); // Log the FormData object
        const response = await axios.post("https://transcript-pm73vvi5fq-ew.a.run.app", formData, config);
        console.log('Response received:', response); // Log the response
        console.log('user.uid:', user.uid);
        if (response.data.status === 'saved') {
          setTranscribing(false);
          navigate('/my-transcripts');
        }
      } 
    } catch (error) {
      console.error('Error:', error); // Log any errors
      setError('Error saving transcription. Please try again.');
      setTranscribing(false);
    }
  };

  function FeatureCard({ icon, title, description }) {
    return (
      <div className="feature-card">
        <div className="feature-icon">{icon}</div>
        <div className="feature-title">{title}</div>
        <div className="feature-description">{description}</div>
      </div>
    );
  }

return (
    <div className="upload-page">
  <video autoPlay loop muted playsInline className="background-video">
    <source src="/Stripe.webm" type="video/webm" />
    <source src="/Stripe.mp4" type="video/mp4" /> 
  </video>
       <Helmet>
        <meta name="description" content="Join the #1 Online Transcription Service, leveraging advanced AI models for unmatched accuracy and efficiency. Our top-tier tool swiftly transcribes your audio to text, saving you substantial time. Experience the ease and precision yourself – try it now for free!" />
      </Helmet>
        <div style={{ textAlign: 'center', paddingTop: '30px' }}>
          <h1>We make transcription easy.</h1>
          <h2>Save endless hours of manual work to get best-in-class transcripts.</h2>
          <div className="upload-container">
            <div className="upload-btn-wrapper" style={{ display: 'flex', alignItems: 'center', gap:'20px'}}>
              <button className="custom-file-upload" onClick={() => fileInputRef.current.click()}>SELECT AUDIO FILE</button>
              <input 
                ref={fileInputRef}
                type="file" 
                name="audiofile" 
                accept=".mp3, .wav, .m4a, .flac, .ogg, .aac, .wma" 
                onChange={handleFileChange} 
                style={{ display: 'none' }} 
              />
          </div>
          {fileUploaded ? (
            <div className="upload-info">
              <p><strong>Audio file:</strong> "{fileName}" successfully uploaded.</p>
              <p><strong>Duration:</strong> {fileDuration.toFixed(2)} minutes.</p>
            </div>
          ) : (
            <p style={{textAlign: 'center'}}>Supported formats: mp3, m4a, wav.</p>
          )}
        {fileUploaded && (
          <button className="custom-file-upload" onClick={handleFileUpload}>
            TRANSCRIBE
          </button>
        )}
        </div>
        {transcribing && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={transcribing}
          >
            <Box sx={{ 
              backgroundColor: 'rgba(198, 159, 219,0.9)', 
              padding: 2, 
              borderRadius: 2.5, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
            }}>
              <img src={loadingGif} alt="Loading..." style={{ width: '100%' }} />
              <Typography variant="h5" component="div" sx={{ marginTop: 2, color:'white', fontWeight: 'bolder'}}>
                Transcription loading, please wait!
              </Typography>
              <Typography variant="h6" component="div" sx={{ marginTop: 1, color:'white'}}>
                It may take up to 5 minutes...
              </Typography>
            </Box>
          </Backdrop>
        )}
        {error && <pre className="error-message">{error}</pre>}
        {needsMoreMinutes && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginLeft: '20px' }}>
            <div className="input-button-container">
              <form>
              <input
                type="number"
                min="5"
                required
                value={purchaseMinutes}
                onChange={handlePurchaseMinutesChange}
                placeholder="Enter minutes to purchase"
                style={{
                  borderRadius: '5px',
                  borderColor: 'var(--powder-blue)'
                }}
              />
              <button 
                className="pricing-upload-page-button" 
                style={{ marginTop: '10px'}} 
                onClick={(event) => {
                  event.preventDefault();
                  createCheckoutSession(user, purchaseMinutes);
                }}
              >
                Purchase Additional Minutes
              </button>
              </form>
            </div>
          </div>
        )}
        <p>{transcription}</p>
        <div>
        <CustomerBanner />
        </div>
        <div className="feature-container">
          <FeatureCard
            icon={<span role="img" aria-label="lock">🎙️</span>}
            title="Automatic Transcription"
            description="Our advanced AI technology transcribes your audio with superhuman accuracy."
          />
          <FeatureCard
            icon={<span role="img" aria-label="lock">🔒</span>}
            title="Secure & Private"
            description="All uploaded audio files are securely processed and deleted after transcription."
          />
          <FeatureCard
            icon={<span role="img" aria-label="globe">🌐</span>}
            title="Multiple Languages"
            description="Supports transcription in 58 languages for global users."
          />
          <FeatureCard
            icon={<span role="img" aria-label="cloud">☁️</span>}
            title="In the cloud"
            description="Our servers do all the heavy lifting for you."
          />
          <FeatureCard
            icon={<span role="img" aria-label="money-bag">💰</span>}
            title="Cost effective"
            description="Unbeatable pricing relative to competitors."/>
          <FeatureCard
            icon={<span role="img" aria-label="thumbs-up">👍🏻</span>}
            title="Pay as you go"
            description="No need to subscribe. Just pay as much as you need."
          />
          {/* You can add more FeatureCard components for additional features */}
        </div>
      </div>
    </div>
  );
}
export default UploadPage;