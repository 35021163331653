import React from 'react';
import './CustomerBanner.css';

const CustomerBanner = () => {
  const logos = [
    '/harvard.svg',
    '/ZeitLogo.svg',
    '/HsgLogo.svg',
    '/CatolicaLogo.svg',
  ];

  return (
    <div className="customer-banner">
         <div className="banner-text">Trusted <br /> Users from</div>
      {logos.map((logo, index) => (
        <img key={index} src={logo} alt="Customer Logo" className="customer-logo" />
      ))}
    </div>
  );
};

export default CustomerBanner;