import React from 'react';
import './SuccessPage.css'; // Import the CSS file

const FailurePage = () => {
  return (
    <div className="full-height">
    <div className="main-content">
    <div className="success-box"> {/* Apply the CSS class */}
      <h1>Transaction Failed</h1>
      <p>Unfortunately, your transaction could not be completed at this time. Please try again later or contact our support team for assistance.</p>
    </div>
    </div> 
    </div>
  );
};

export default FailurePage;