import { Link } from 'react-router-dom';
import useAuth from './useAuth';
import './App.css';
import './Navbar.css';
import Logo from './logo.png';
import { useState } from 'react';


function Navbar() { 
  const { user, handleLogout } = useAuth(); // Use the custom hook
  const [isOpen, setIsOpen] = useState(false); // Add this line
  const toggleMenu = () => setIsOpen(!isOpen); // Add this function



  return (
      <nav className="navbar">
        <div className="navbar-container">
        <button onClick={toggleMenu} className="hamburger">&#9776;</button>
          <Link to="/upload" className="navbar-links">
          <img src={Logo} className='navbar-logo' alt="Logo" />
          </Link>
          <ul className={`navbar-menu ${isOpen ? 'show' : ''}`}>
            {!user && (
              <li className="navbar-item">
                <Link to="/auth" className="navbar-links">Login / Register</Link>
              </li>
            )}
          <li className="navbar-item">
             <Link to="/my-transcripts" className="navbar-links">My Transcripts</Link>
            </li>
            <li className="navbar-item">
            <Link to="/about" className="navbar-links">About</Link>
          </li>
          </ul>
          {user && (
          <div className="logout-container">
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      )}
        </div>
      </nav>
    );
  }

export default Navbar;