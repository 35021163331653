import React, { useState, useEffect, useContext } from 'react';
import { db } from './firebase'; // Import Firestore database
import { collection, getDocs } from 'firebase/firestore';
import useAuth from './useAuth'; // Import the useAuth hook
import './Transcript.css'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserContext from './UserContext';
import './App.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log('Children:', children);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children && (
        <Box sx={{ p: 3 }}>
           <Typography 
            sx={{ 
              padding: '1em 1em 1em 1em', // Add padding
              backgroundColor: '#F7F1F9', // Change the background color
              borderRadius: '10px', // Add rounded corners
              whiteSpace: 'pre-wrap', // Preserve line breaks and white space
              color: '#422434',
              border: '1px dashed',
              borderColor: '#C69FD4',
              boxShadow: '-4px -4px 4px rgba(201, 160, 220, 0.1)'
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



const Transcripts = () => {
  const [transcripts, setTranscripts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


useEffect(() => {
    console.log('User:', user);
    if (!user || !user.uid) {
      console.log('Invalid user or user ID');
      return;
    }

    console.log('User ID:', user.uid); // Log the user ID

    const fetchTranscripts = async () => {
      setLoading(true);
      try {
        const q = collection(db, 'users', user.uid, 'transcriptions');
        const querySnapshot = await getDocs(q);
        let transcripts = [];
        querySnapshot.forEach((doc) => {
          transcripts.push({ id: doc.id, ...doc.data() });
        });
        console.log('Transcripts:', transcripts); 
        setTranscripts(transcripts);
      } catch (error) {
        console.error('Error fetching transcripts:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching the data
      }
    };


    fetchTranscripts();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while the transcripts are being fetched
  }
  
  if (!transcripts.length) {
    return (
      <h2 style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      }}>
        No transcripts yet. Start generating your first transcript now. 
      </h2>
    );
  }
  
  return (
    <div className="main-content">
    <Box
      sx={{ flexGrow: 1, display: 'flex', borderColor:'#C69FD4', margin: '20px 40px 0px 40px'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderRadius:'20px', marginY:'25px', borderColor: '#C69FD4', width: '20%', padding: '1em', flex:'0 0 auto', boxShadow: '4px 2px 4px rgba(201, 160, 220, 0.1)', '& .MuiTab-root': { // Apply styles to all Tab components
          color: '#0D160B', // Default color
          '&:hover': { // On hover
            color: '#97BCD8',
          },
          '&.Mui-selected': { // On active
            color: '#C69FD4', // 
        }
      }
          }}
      >
        {transcripts.map((transcript, index) => (
  <Tab key={transcript.id} label={transcript.audioFile} {...a11yProps(index)}
  sx={{
    color: '#0D160B',
    borderBottom: '1px solid #C69FD4',
    borderRadius: '0px',
    borderWidth: '40%'
}} />
))}
      </Tabs>
      {transcripts.map((transcript, index) => (
typeof transcript.transcript === 'string' && transcript.transcript.trim() !== "" ? (
      <TabPanel key={transcript.id} value={value} index={index}>
      {transcript.transcript}
    </TabPanel>
  ) : (
    <TabPanel key={transcript.id} value={value} index={index}>
      {"Transcript is empty"}
    </TabPanel>
  )
))}
    </Box>
    </div>
  );
};

export default Transcripts;


