import { useState, useEffect } from 'react';
import { auth, signOutAuth, doc, db, onSnapshot, getDoc, collection, getDocs} from './firebase'
import axios from 'axios';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, onIdTokenChanged} from 'firebase/auth';
import { createUser } from './firestoreSetup';
import { updateMinutes} from './Checkout';

export default function useAuth() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null); 
  const [message, setMessage] = useState(null); 
  const [loading, setLoading] = useState(true);

  if (user && user.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const userRef = doc(db, 'users', authUser.uid);
        onSnapshot(userRef, async (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            const token = await authUser.getIdToken(); // Get the token
            setUser({ uid: authUser.uid, email: authUser.email, emailVerified: authUser.emailVerified, token, ...data }); // add emailVerified property
          }
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // This will refresh the token every time it changes
    const unsubscribeToken = onIdTokenChanged(auth, async (authUser) => {
      if (authUser) {
        const token = await authUser.getIdToken();
        setUser((currentUser) => ({ ...currentUser, token }));
      }
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribe();
      unsubscribeToken();
    };
  }, [auth, db]);


  const handleLogout = async () => {
    setLoading(true)
    try {
      await signOutAuth(auth);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true)
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const { user } = userCredential;
  
      // Check if the user document already exists
      const userRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userRef);
  
      // If the user document does not exist, create it
      if (!docSnap.exists()) {
        await createUser({ uid: user.uid, email: user.email });
      }
  
      setUser(user);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    finally {
      setLoading(false);
    }
  };
  const handleLogin = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        await signOutAuth(auth);
        setUser(null);
      } else {
        setUser(user);
        setMessage(null); 
      }
    } catch (error) {
      console.error(error);
      switch (error.code) {
        case 'auth/invalid-email':
          setError('Invalid email format.');
          break;
        case 'auth/user-disabled':
          setError('This account has been disabled.');
          break;
        case 'auth/user-not-found':
          setError('No account found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password.');
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailVerification = async () => {
    if (user) {
      try {
        await sendEmailVerification(auth, user);
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    }
  };

  const handleRegister = async (email, password, confirmPassword) => {
    setLoading(true);
    try {
      if (password !== confirmPassword) {
        throw new Error('Passwords do not match.');
      }
  
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        throw new Error('This email is already registered. Please log in.');
      }
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Ensure the user is not null before proceeding
      if (!user) {
        throw new Error('User creation failed. Please try again.');
      }
  
      await sendEmailVerification(user);
      await createUser({ uid: user.uid, email: user.email });
  
      // Ensure the user is signed out after registration
      await signOutAuth(auth);
  
      // Clear any previous error messages
      setError(null);
      setMessage('Registration successful! Please check your email for a verification link.');
      setUser(null);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (email) => {
    setLoading(true)
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error.code === 'auth/missing-email') {
        throw new Error('Please provide your email before requesting a new password.');
      }
      console.error(error);
      setError(error);
    }
    finally {
      setLoading(false);
    }
  };

  return { user, setUser, handleLogout, handleLogin, handleRegister, handleGoogleLogin, handlePasswordReset, error, setError, message, loading, onSnapshot};
}