// App.js
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'
import UploadPage from './UploadPage';
import AuthPage from './AuthPage';
import Navbar from './Navbar';
import useAuth from './useAuth'; // Import the custom hook
import Transcripts from './Transcripts';
import UserContext from './UserContext';
import Pricing from './Pricing';
import Success from './Success';
import Failure from './Failure';
import Footer from './Footer';

function App() {
  const {user, setUser, handleLogout, handleLogin, handleRegister, handleGoogleLogin, handlePasswordReset, error,setError, message, loading, onSnapshot, token, updateUserMinutes} = useAuth();
  
  if (loading) {
    return <div>Loading...</div>; // Replace this with your loading spinner or placeholder
  }

  return (
    <UserContext.Provider value={{ user, setUser, handleLogout, handleLogin, handleRegister, handleGoogleLogin, handlePasswordReset, error, setError, message, loading, onSnapshot, token, updateUserMinutes }}>
      <Router>
        <Navbar />
        <div className="full-height">
        <Routes>
          <Route path="/" element={<UploadPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/my-transcripts" element={<Transcripts />} />
          <Route path="/about" element={<Pricing />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Failure />} />
        </Routes>
        <Footer />
        </div>
      </Router>
    </UserContext.Provider>
  );
}

export default App;