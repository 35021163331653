import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { db, doc, getDoc, onSnapshot} from './firebase';
import './SuccessPage.css'; // Import the CSS file

const SuccessPage = () => {
  const [minutes, setMinutes] = useState(0);
  const [loading, setLoading] = useState(true); // Add a loading state
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, 'users', user_id);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setTimeout(() => {
            setMinutes(userDoc.data().minutes);
            setLoading(false); // Set loading to false after updating minutes
          }, 100);
        } else {
          console.log(`No document found for user_id: ${user_id}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();

  // Set up a Firestore listener
  const userRef = doc(db, 'users', user_id);
  const unsubscribe = onSnapshot(userRef, (docSnap) => {
    if (docSnap.exists()) {
      setMinutes(docSnap.data().minutes);
    }
  });

  return () => unsubscribe();
  }, [user_id]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading message while fetching data
  }

  return (
    <div className="full-height">
    <div className="main-content">
    <div className="success-box">
      <h1>Thank you for your purchase!</h1>
      <p>Your transaction was successful.</p>
      <p>Your new balance is: <strong>{minutes} minutes</strong>.</p>
    </div>
    </div>
    </div>
  );
};

export default SuccessPage;