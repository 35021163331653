import React from 'react';

const Footer = () => {
  const footerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 50px',
    backgroundColor: '#f4f4f4',
    borderTop: '1px solid #e1e1e1',
    marginTop: 'auto',
    flexShrink: '0',
  };

  const copyrightStyle = {
    display: 'flex',
    alignItems: 'center'
  };

  const heartStyle = {
    color: 'red',
    marginLeft: '5px',
    marginRight: '5px'
  };

  return (
    <footer style={footerStyle}>
      <div style={copyrightStyle}>
        © 2023 Transcripter — All rights reserved.
      </div>
      <div>
        {/* Placeholder for other links, e.g., Datenschutz, Impressum, AGB, Kontakt */}
      </div>
    </footer>
  );
};

export default Footer;
