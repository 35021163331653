import { storage, ref, uploadBytesResumable, getDownloadURL } from "./firebase";



export const onFileChange = async (e, userId) => {
  const file = e.target.files[0]; 
  const storageRef = ref(storage, `audio_files_transcripter/${userId}/${file.name}`);
  // Upload the file to the bucket
  const uploadTask = uploadBytesResumable(storageRef, file);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
    }, 
    (error) => {
      // Handle unsuccessful uploads
      console.log('Upload failed:', error);
      throw error;
    }
  );

  // Wait for the upload to complete and get the download URL
  await new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      null, 
      (error) => reject(error), 
      () => resolve()
    );
  });

  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  console.log('File available at', downloadURL);
  return downloadURL;
};