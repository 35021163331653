import {db, collection, doc, setDoc, addDoc, getDocs, query, where } from "./firebase";


export const createUser = async (user) => {
    const userRef = doc(collection(db, 'users'), user.uid);
    await setDoc(userRef, {
      email: user.email,
      minutes: 10,
      stripeCustomerId: null
    });
  };

  export const createTranscription = async (userId, audioFile, transcript) => {
    const transcriptionsRef = collection(doc(db, 'users', userId), 'transcriptions');
    await addDoc(transcriptionsRef, {
      audioFile: audioFile,
      transcript: transcript,
      timestamp: new Date()
    });
  };

  export const updateStripeCustomerId = async (userId, stripeCustomerId) => {
    const userRef = doc(db, 'customers', userId);
    await updateDoc(userRef, {
      stripeCustomerId: stripeCustomerId
    });
  };

  /*
  export const mergeCustomersAndUsers = async () => {
    // Get all customers
    const customersSnapshot = await getDocs(collection(db, 'customers'));
  
    // For each customer
    customersSnapshot.forEach(async (customerDoc) => {
      const customerId = customerDoc.id;
      const customerData = customerDoc.data();
  
      // Check if a user with the same ID exists
      const userRef = doc(db, 'users', customerId);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        // If the user doesn't exist, create a new user with the same ID and data
        await setDoc(userRef, {
          ...customerData,
          minutes: 0,
          stripeCustomerId: null
        });
  
        // Create the 'transcriptions' and 'payments' subcollections
        const transcriptionsRef = collection(userRef, 'transcriptions');
        await addDoc(transcriptionsRef, {
          audioFile: '',
          transcript: '',
          timestamp: new Date()
        });
  
        const paymentsRef = collection(userRef, 'payments');
        await addDoc(paymentsRef, {
          amount: 0,
          currency: 'usd',
          timestamp: new Date(),
          stripeCheckoutSessionId: ''
        });
      }
    });
  };
*/