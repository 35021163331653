import React, { useContext, useState, useEffect } from 'react';
import { doc, onSnapshot} from 'firebase/firestore';
import { db } from './firebase';
import { createCheckoutSession} from './Checkout';
import UserContext from './UserContext';
import Faq from "react-faq-component";
import { useNavigate } from 'react-router-dom';


function Pricing() {
  const { user } = useContext(UserContext);
  const [purchaseMinutes, setPurchaseMinutes] = useState(0);
  const [userMinutes, setUserMinutes] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    console.log(user);
    if (user && user.uid) {
      const userRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setUserMinutes(doc.data().minutes);
        } else {
          console.error('User does not exist');
        }
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [user]);

  const handlePurchaseMinutesChange = (event) => {
    setPurchaseMinutes(event.target.value);
  };

  const handlePurchaseMinutes = async (event) => {
    event.preventDefault();
    if (!user) {
      navigate('/auth'); // Redirect to AuthPage
      return;
    } 
    if (!purchaseMinutes || purchaseMinutes <= 0) {
      setError('Invalid input. You have to purchase at least 5 minutes.');
    } else {
      try {
        setLoading(true);
        await createCheckoutSession(user, purchaseMinutes);
        // Navigate to the next page or update the UI here
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
  };

  const data = {
    title: "FAQ",
    rows: [
        {
            title: "What is Transcripter?",
            content: `Transcripter is an online transcription tool. It uses OpenAI's whisper  automatic speech recognition to transcribe your audio files. The whisper model system trained on 680,000 hours of multilingual and multitask supervised data collected from the web which leads to exceptional accuracy.`,
        },
        {
            title: "How do I use this service?",
            content: `Just upload your file and make sure to have enough minutes in your balance. You get 10 free minutes when creating an account.`,
        },
        {
            title: "How much does it cost?",
            content: `We charge 0.1€ per minute. This helps us cover server costs as well as calling the OpenAI API`,
        },
        {
            title: "How many languages can I transcribe?",
            content: `We currently cover the following 58 languages: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.`,
        },
        {
            title: "I encountered a problem. Who can I contact for help?",
            content: 'Please contact julian@transcripter.de for any issues.',
        },
        {
          title: "Do you offer a revenue sharing program?",
          content: 'Yes, and we love to partner with our users. Please reach out at julian@transcripter.de if you are interested.',
      },
    ],
};

const styles = {
    fontFace:"GeistSans",
    bgColor: 'f9f9f9',
    arrowColor: "#C69FD4",
    titleTextColor: "text-blue-900 font-bold",
    titleTextWeight: "bold",
    titleTextSize: "2.5em",
    rowTitleColor: "text-blue-700",
    rowContentColor: 'text-gray-600',
    rowTitleTextSize: '1em',
    rowTitleWeight: '800',
    rowContentTextSize: 'text-md',
    rowContentPaddingTop: 'pt-2',
    rowContentPaddingBottom: 'pb-2',
    rowContentPaddingLeft: 'pl-2',
    rowContentPaddingRight: 'pr-2',
};

const config = {
    animate: true,
    tabFocus: true
};

return (
  <div className="pricing-container">
    {loading && <div className="spinner"></div>}
    <div className="balance-info">
      <h1>My Balance</h1>
      <p>Current balance: {userMinutes.toFixed(2)} minutes</p>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <form onSubmit={handlePurchaseMinutes}>
 <input
    className="minute-input"
    type="number"
    min="5"
    value={purchaseMinutes}
    onChange={handlePurchaseMinutesChange}
    placeholder="Enter minutes"
  />
  <button 
    className="pricing-button" 
    style={{ marginTop: '10px' }} 
    onClick={() => createCheckoutSession(user, purchaseMinutes)}
  >
    Purchase Minutes
  </button>
  </form>
  {error && <p>{error}</p>}
</div>
</div>
    <div className="faq-info">
      <Faq
        data={data}
        styles={styles}
        config={config}
      />
    </div>
  </div>
)};

export default Pricing;