import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, signOut} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, doc, onSnapshot, getDoc, setDoc, addDoc, collection, updateDoc, getDocs, query, where, increment, runTransaction} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getStorage, ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';


// Firebase configuration
const firebaseConfig = {
        "apiKey": "AIzaSyCN6FYeio_R5lnj_C5gV79WKidAaim8JAs",
        "authDomain": "transcripter.de",
        "databaseURL": "https://transcription-se-1692118454496-default-rtdb.europe-west1.firebasedatabase.app",
        "projectId": "transcription-se-1692118454496",
        "storageBucket": "transcription-se-1692118454496.appspot.com",
        "messagingSenderId": "1807710500",
        "appId": "1:1807710500:web:d0c0f8ce087564824b9bb1",
        "measurementId": "G-CP3ZGD78P2"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get instances of Auth and Firestore
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
export const signOutAuth = signOut;
const functions = getFunctions(firebaseApp, 'europe-west2');
const payments = getStripePayments(firebaseApp, {
        productsCollection: "products",
        customersCollection: "customers",
        stripePublishableKey: 'pk_live_51NpIWlLgUN2PxGf93f7HiaRjnnBXkBKQJ2xui70Hxz2LV0QYU5UaVKnc6mzFtDWTo9Y0NvupYWO1NVuJN9p2ogQZ00d0lmMGRZ', // Your Stripe publishable key
      });
const storage = getStorage(firebaseApp);


export { auth, db, doc, onSnapshot, getDoc, collection, addDoc, setDoc, updateDoc, getDocs, query, where, increment, runTransaction, functions, httpsCallable, payments, storage, ref, uploadBytesResumable, getDownloadURL};
// Use emulators for Auth and Firestore when in development environment
if (window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFirestoreEmulator(db, 'localhost', 8083);
  }

