import React, { useState, useEffect, useContext} from 'react';
import useAuth from './useAuth';
import { Link, useNavigate } from 'react-router-dom';
import googleLogo from './googlelogotrans.png';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function AuthPage() {
  const { user, handleLogin, handleRegister, handleLogout, handleGoogleLogin, handlePasswordReset, error, setError, loading, message} = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);



  useEffect(() => {
    if (user && user.emailVerified) { // check if email is verified
      setIsVerified(true);
      navigate('/upload');
    } else {
      setIsVerified(false);
    }
  }, [user, navigate]);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isLogin) {
        await handleLogin(email, password);
        setIsVerified(true);
      } else {
        if (password !== confirmPassword) {
          throw new Error('Passwords do not match');
        }
        await handleRegister(email, password, confirmPassword);
        setIsVerified(false);
      }
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  const handleGoogleLoginClick = async () => {
    try {
      await handleGoogleLogin();
    } catch (error) {
      console.error(error);
    }
  };

  const handleForgotPasswordClick = () => {
    setResetPasswordModalOpen(true);
  };

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      await handlePasswordReset(resetPasswordEmail);
      setResetPasswordModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="auth-container">
      <h1>{isLogin ? 'Welcome back' : 'Create your account'}</h1>
      {error && <p className="error-message">{error}</p>}
      {message && <p className="success-message">{message}</p>}
      <form onSubmit={handleSubmit} className="auth-form">
        <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
        {!isLogin && <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />}
        <p>
          <span className="auth-link" onClick={handleForgotPasswordClick}>Forgot password? </span>
        </p>
        <button className="auth-button" type="submit" disabled={loading}>
          {isLogin ? 'Login' : 'Register'}
        </button>
        {isLogin ? 
          <p>Don't have an account yet? <span className="auth-link" onClick={() => {setIsLogin(!isLogin);}} >Sign up</span></p> 
          : 
          <p>Already have an account? <span className="auth-link" onClick={() => {setIsLogin(!isLogin);}} >Log in</span></p>
        }
        <div className="divider-container">
          <hr className="divider-line" />
          <span className="divider-text">OR</span>
          <hr className="divider-line" />
        </div>
        <button className="auth-button google" onClick={handleGoogleLoginClick} disabled={loading}>
          <img src={googleLogo} alt="Google Logo" />
          Continue with Google
        </button>
      </form>
      {user && <button className="auth-button" onClick={handleLogout}>Logout</button>}
      <div>
      <Modal isOpen={resetPasswordModalOpen} onRequestClose={() => setResetPasswordModalOpen(false)}
       style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: '10%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
          position: 'relative',
          width: '100%',
          maxWidth: '400px',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          fontSize: '1em',
        },
      }}>
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPasswordSubmit}>
        <input type="email" value={resetPasswordEmail} onChange={e => setResetPasswordEmail(e.target.value)} placeholder="Email" />
        <button className="auth-button" type="submit">Send Reset Email</button>
      </form>
    </Modal>
    </div>
    </div>
  );
}

export default AuthPage;